
.page-title {
    height: 100vh;
    vertical-align: middle;
    // background-color: var(--color-five);
    // opacity: 0.8;
    // background-image: radial-gradient(var(--color-three) 0.5px, transparent 0.5px), radial-gradient(var(--color-three) 0.5px, transparent 0.5px);
    // background-size: 20px 20px;
    // background-position: 0 0,10px 10px;
    background: #FFFFFF;
    background: url('./img/infoBackground.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-color:rgba(0, 0, 0, 0.2);

    .title {
        position: absolute;
        width: 100%;
        margin: auto;
        top: 50%;
        text-align: center;

        h1 {
            font-size: 100px;
            font-weight: 900;
            color: var(--color-three);
            text-shadow: 0px 4px 10px #000000;
        }
    }
}

.info-box {
    width: 90%;
    height: fit-content;
    max-width: 1200px;
    margin: 1rem auto;
    color: #000000;

    .title{
        padding: 1rem 0;

        .title-h{
            font-size: 30px;
        }
    }

    .content{

        .content-h {
            font-size: 20px;
            font-weight: 400;

            .subtitle {
                padding-top: 2rem;
                font-weight: 900;
            }

            li {
                margin-left: 3rem;
                list-style-type: disc;
            }
        }
        
    }
}