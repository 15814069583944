// VARIABLES
$breakPointTablet: 768px;
$breakPointMobile: 600px;
$navBarHeight: 10rem;

.servicehome {
    width: 100%;
    height: calc(var(--vh, 1vh) *100);
    margin: 0;
    padding: $navBarHeight 0 0 0;
    background: #FFFFFF;
    background: url('./img/servicesBackground.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover !important;
    display: flex;
    // background: linear-gradient(#222, #EEE);

    .servicehome-img {
        position: absolute;
        float: right;
        right: 5rem !important;
        width: 40rem;
    }

    .servicehome-title {
        position: absolute;
        width: 100%;
        color: var(--color-three);
        margin: 0;
        align-items: center;
        text-align: center;
        top: 40%;
        opacity: 1 !important;

        h1 {
            font-size: 60px;
            margin: 0;
            font-weight: 900 !important;
            opacity: 1 !important;
        }

        .bounceArrow{
            margin: 1rem auto 1rem auto;
            font-size: 100px;
            -moz-animation: bounceArrow 2s infinite;
            -webkit-animation: bounceArrow 2s infinite;
            animation: bounceArrow 2s infinite;
            opacity: 1 !important;
          }
          
          @keyframes bounceArrow {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-30px);
            }
            60% {
              transform: translateY(-15px);
            }
          }

        h2 {
            margin: 0;
            font-size: 25px;
            color: #FFFFFF;
        }
    }

    .wave {
        bottom: -1px;
        position: absolute;
        width: 100%;
        height: 100px;
    }
}

@media (max-width: 1000px) {
    .servicehome {
        .servicehome-title {
            left: 0;
            padding-left: 0;
            margin-left: 0;
            width: 100%;
            color: var(--color-three);
            
            h1 {
                opacity: 1 !important;
                font-size: 100px;
            }
        }
        .servicehome-img {
            width: 100% !important;
            opacity: 0.2;
            right: 0 !important;
            float: left !important;
        }
    }
}

@media (max-width: $breakPointTablet) {
    .servicehome {
        .servicehome-title {
        }
    }
}

@media (max-width: $breakPointMobile) {
    .servicehome {
        .servicehome-title {
            h1 {
                font-size: 45px;
                opacity: 1 !important;
            }
            h2 {
                font-size: 20px;
            }
        }
    }
}