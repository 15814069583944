// VARIABLES
$breakPointTablet: 768px;
$breakPointMobile: 600px;

.contactForm {
    width: 100%;
    transition: 0.4s ease-in-out;

    .formRow {
        width: 100%;

        .col-6,
        .col {
            width: 100%;
            padding: 1rem;
            input,
            textarea {
                width: 100%;
                background: #EEEEEE;
                border-radius: 20px;
                padding: 0.6rem;
                border: none;
            }
            textarea{
                height: 20rem !important;
            }
            .errorMessage {
                padding-left: 2rem;
                margin: 0;
                color: red;
            }
        }
    }

    .submit-btn{
        margin: 1rem;
        padding: 1rem 2rem;
        border-radius: 20px;
        background: var(--color-five);
        color: #FFFFFF;
        border: none;
        box-shadow: rgb(38, 57, 77) 0px 10px 20px -10px;   
        transition: 0.4s;     
    }

    .submit-btn:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
}

.mailSend {
    display: none;
    vertical-align: center;
    color: var(--color-five);
}
