// VARIABLES
$breakPointTablet: 768px;
$breakPointMobile: 600px;

@keyframes slideInFromBottom {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
    }
}

// FONTS
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

// COLOR DEFINITIONS

:root {
    --color-one: #188c54;
    --color-two: #004b50;
    --color-three: #e8ffe3;
    --color-four: #75562d;
    --color-five: #04ad8f;
    --color-six: #f7fff2;
    --color-seven: #188c5483;



    $breakPointTablet: 768px;
    $breakPointMobile: 600px;
}


// SCROLLBAR

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 15px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--color-two);
}
// GENERAL SETUP

* {
    margin: 0;
    padding: 0;
    box-sizing:border-box;
    font-family: 'Poppins', sans-serif;
}

html,
body,
.App {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth !important;
    background-color: #ffffff;
    // background-image: url('./components/parts/About/topography.svg') ;
}

a {
    text-decoration: none;
    color: #000000;
}

li {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.row {
    width: 100%;
    padding: 0;
    margin: 0;
    align-content: center;
    align-items: center;
}

.tag {
  opacity: 0;
  transform: translate(0, 1vh);
  transition: all 1s;
}

.tag.visible {
  opacity: 1;
  animation: 1.4s ease 0s 1 slideInFromBottom;
}

.card {
    border-radius: 20px !important;
}

.invisible {
    display: none !important;
}