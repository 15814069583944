// VARIABLES
$breakPointTablet: 768px;
$breakPointMobile: 600px;
$navBarHeight: 7rem;

@keyframes slideInFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.home {
    width: 100%;
    height: calc(var(--vh, 1vh) *100);
    margin: 0;
    padding: $navBarHeight 0 0 0;
    background: url('./img/workspace.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover !important;

    .home-title {
        position: absolute;
        color: var(--color-three);
        margin: 0 1rem 0 calc(100px + 30px);
        top: 40%;
        animation: 0.8s ease-out 0s 1 slideInFromLeft;

        h1 {
            font-size: 60px;
            margin: 0;
        }

        h2 {
            margin: 0;
            font-size: 25px;
            color: #FFFFFF;
        }
    }

    .wave {
        bottom: -1px;
        position: absolute;
        width: 100%;
        height: 100px;
    }
}

@media (max-width: 1000px) {
    .home {
        .home-title {
            margin-left: 50px;
        }
    }
}

@media (max-width: $breakPointTablet) {
    .home {
        .home-title {
            margin-left: 30px;
        }

        .wave {
            height: 70px !important;
        }
    }
}

@media (max-width: $breakPointMobile) {
    .home {
        .home-title {
            h1 {
                font-size: 35px;
            }
            h2 {
                font-size: 20px;
            }
        }

        .wave {
            height: 70px !important;
        }
    }
}