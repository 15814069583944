// VARIABLES
$breakPointTablet: 768px;
$breakPointMobile: 600px;

.contact {
    padding-top: 1rem;
    margin-bottom: 1rem;

    .row {
        .contact-box {
            width: 90%;
            height: fit-content;
            max-width: 1200px;
            margin: auto;
            display: grid;
            grid-template-columns: repeat(2, 1fr) !important;
            grid-gap: 1rem;

            .contact-col {
                width: 100%;

                h3 {
                    font-size: 35px;
                    z-index: 100;
                }

                p{
                    z-index: 20;
                }
                .contact-img {
                    width: 100%;
                    margin-left: -45px;
                }
            }
        }
    }
}

@media (max-width: $breakPointTablet) {
    .contact {
        .row{
            .contact-box {
                grid-template-columns: repeat(1, 1fr) !important;

                .contact-img {
                    width: 120% !important;
                }
            }
        }
    }
}