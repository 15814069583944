// VARIABLES
$breakPointTablet: 768px;
$breakPointMobile: 600px;

.about-box {
    width: 90%;
    height: fit-content;
    max-width: 1200px;
    border-radius: 50px;
    background-color: #DDDDDD;
    opacity: 1;
    background-image:  repeating-radial-gradient( circle at 100% 0, transparent 0, var(--color-three) 100px ), repeating-linear-gradient( #d5ffcc, var(--color-three) );
    margin: 1rem auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 1rem;
    color: #000000;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


    .about-text {
        margin: auto 0;
        margin-left: 2rem;

        h3 {
            font-weight: 600;
        }
    }

    .about-profile {
        margin: auto 0;
        padding: 2rem 0;

        .about-photo {
            width: 300px;
            height: 300px;
            border-radius: 50%;
            overflow: hidden;
            display: block;
            margin-left: auto;
            margin-right: auto;
            background-color: #FFFFFF;

            img {
                width: 70%;
                // height: auto;
                // border-radius: 50%;
                overflow: none;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .about-name {
            margin: 2rem;

            h5 {
                font-weight: 500;
            }
        }
    }
}

@media (max-width: $breakPointTablet) {
    .about-box {
        grid-template-columns: repeat(1, 1fr) !important;

        .about-text {
            margin: 2rem;
        }

        .about-profile {
            .about-name {
                margin: 2rem 4rem;
            }
        }
    }
}