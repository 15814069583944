// VARIABLES
$breakPointTablet: 768px;
$breakPointMobile: 600px;

.box {
    width: 90%;
    height: fit-content;
    max-width: 1200px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 1rem;
    padding-top: 0rem;

    .image {
        width: 100% !important;
        margin: auto;

        .img{
            border-radius: 20px;
            width: 100%;
            margin: auto;
        }
    } 

    .text {
        margin: 0 1rem;

        .title {
            h3 {
                font-size: 35px;
                font-weight: 600;
            }
        }
        
        .explain {
            h4 {
                font-size: 18px;
                font-weight: 500;
            }
        }
    }
}

@media (max-width: 1000px) {
    .box {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

// @media (max-width: $breakPointTablet) {
//     .projects-box {
//         .projects-slide {
//             width: 650px !important;
//         }

//     }
// }

// @media (max-width: $breakPointTablet) {
//     .projects-box {

//         .projects-slide {
//             width: 350px !important;
//         }

//     }
// }