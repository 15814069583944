// VARIABLES
$breakPointTablet: 768px;
$breakPointMobile: 600px;

.footer {
    padding: 5rem 0;
    margin: 0;
    width: 100%;
    height: fit-content;
    background: var(--color-three);
    color: #000000;
    background-image: url('../parts/img/topography.svg');
    background-repeat: repeat;
    position: relative;
    overflow: visible;
    border-radius: 0 0 0 0;

    a{
        color: #000000;
    }

    .footer-grid {
        height: fit-content;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        // grid-auto-flow: column;
        grid-gap: 1rem;
        margin: 0 0 0 5rem;

        .footer-element-col {
            display: grid;

            .footer-element {
                margin: 0.2rem 0;
            }

            .footer-icons {
                display: flex;
                text-align: center;
                justify-content: space-between;
                width: 100px;
            }

            .footer-copyright {
                width: 100%;
            }
        }
    }
}

@media (max-width: $breakPointTablet) {
    .footer {
        .footer-grid {
            grid-template-columns: repeat(2,1fr) !important;
            text-align: center;
            margin: 0 2rem;
            
            .footer-icons {
                width: 20%;
                margin: 0.5rem auto;
            }
        }
    }
}

@media (max-width: $breakPointMobile) {
    .footer {
        .footer-grid {
            grid-template-columns: repeat(1,1fr) !important;
            text-align: center;
            
            .footer-icons {
                width: 40%;
                margin: 0.5rem auto;
            }
        }
    }
}