.section {
    width: 100%;
    height: fit-content;
    padding: 11rem 0 12rem 0;
    margin: 0;

}


#about {
    border-radius: 0 0 50% 150% !important;
    margin-top: -0.6rem;
}

#projects {
}

#contact {
    background-color: #FFFFFF;
}
// .section:first-of-type{
//     padding-top: 11rem;
// }

// .section:last-of-type{
//     padding-bottom: 11rem;
// }