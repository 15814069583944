// VARIABLES
$breakPointTablet: 768px;
$breakPointMobile: 600px;
$navBarHeight: 7rem;
$navBarHeightMobile: 5rem;


.header {
    background: var(--color-three);
    background: linear-gradient(90deg, var(--color-three) 0%, var(--color-two) 80%);  
    border-radius: 50px;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 20px;
    transition: 0.4s;
    position: fixed;
    width: calc(100% - 200px);
    height: $navBarHeight;
    border: none;
    z-index: 5;
    display: flex;
    justify-content: space-between;


    .logo{
        margin: 0;
        margin-left: 30px;
        padding:0;
        height: 100%;
        width: fit-content;
        display: block;

        a {
            margin: 0;
            padding: 0;
            height: 100%;
            width: fit-content;
            display: block;

            .logo-title {
                color: #000000;
                display: inline-flex;
                margin: 2.2rem 0 0 0;

                h4, h6 {
                    font-weight: 900;
                    margin-top: 0;
                    margin-bottom: 0;
                }

                .logo-title-tree {
                    color: var(--color-one);

                    .tree-title {
                        width: 35px;
                        margin-top: -20px;
                    }
                }
            }

            .logo-slogan {
                color: var(--color-four);
            }
        }
    }

    .menu {
        position: relative;
        width: fit-content;
        vertical-align: center;

        .hamburger {
            position: relative;
            top: 0 !important;
            right: 0 !important;
        }
    }

    .contactButton {
        position: absolute;
        margin: 0 auto;
        align-self: center;
        left: calc(50% - 80px);

        a{
            color: #FFFFFF;
            border-radius: 20px;
            border: solid #FFFFFF 2px;
            padding: 1rem 2rem;
            transition: all 0.5s;
            
        }

        a:hover {
            border-radius: 60px;
        }
    }
}

@media (max-width: 1000px) {
    .header {
        margin: 0;
        border-radius: 0;
        width: calc(100%);
        display: flex;
    }
}

@media (max-width: $breakPointTablet) {
    .header {
        height: 6rem;  
        margin: 0;
        border-radius: 0;
        width: calc(100%);
        
        .logo {

        }

        .contactButton {
            display: none;
        }
    }
}

@media (max-width: $breakPointTablet) {
    .header {
        height: $navBarHeightMobile;  
        margin: 0;
        width: calc(100%);
        border-radius: 0;
        
        .logo {
            a {
                .logo-title {
                    h4 {
                        font-size: 12px;
                        
                        .tree-title {
                            width: 20px !important;
                            height: auto;
                            margin-top: -10px !important;
                        }
                    }
                }

                .logo-title {

                    margin-top: 1.5rem;

                    h6 {
                        font-size: 10px;
                    }
                }
            }
        }
    }
}