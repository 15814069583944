// VARIABLES
$breakPointTablet: 768px;
$breakPointMobile: 600px;

.projects-box {
    width: 90%;
    height: fit-content;
    max-width: 1200px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 1rem;
    padding-bottom: 2rem;
    background-image: url('../img/tree.svg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: auto 100%;
    overflow-x: visible;


    .projects-slide {
        width: 600px !important;
        margin: auto;

        .owl-theme {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            border-radius: 20px;
            .item {
                border-radius: 20px;
                img {
                    border-radius: 20px;
                }
            }
        }
    } 

    .projects-text {
        margin: 0 1rem;

        .projects-title {
            h3 {
                font-size: 35px;
                font-weight: 600;
            }
        }
        
        .projects-explain {
            h4 {
                font-size: 18px;
                font-weight: 500;
            }
        }
    }
}

@media (max-width: 1000px) {
    .projects-box {
        grid-template-columns: repeat(1, 1fr) !important;

        .projects-slide {
            width: 700px !important;
        }

    }
}

@media (max-width: $breakPointTablet) {
    .projects-box {
        .projects-slide {
            width: 650px !important;
        }

    }
}

@media (max-width: $breakPointTablet) {
    .projects-box {

        .projects-slide {
            width: 350px !important;
        }

    }
}